@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

body.dark-mode{
  background-color: #121212;
  color: #a0aec0;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}

/* Dark Mode Styles */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Hero section in Dark Mode */
body.dark-mode .hero {
  background: url(./images/hero-bg.svg) top center no-repeat; /* You can add a separate hero background for dark mode */
  background-size: cover;
}

/* Footer section in Dark Mode */
body.dark-mode .footer {
  background: url(./images/footer.svg) top center no-repeat; /* Use a different footer background if desired */
  background-size: cover;
}

/* Dark Mode for CTA Section */
body.dark-mode .cta {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) fixed center center;
}

/* Example of changing text color and link styles */
body.dark-mode a {
  color: #a0aec0; /* Lighter link color for dark mode */
}

/* Button styles for dark mode */
body.dark-mode button {
  background-color: #333;
  color: white;
}

body.dark-mode button:hover {
  background-color: #444;
}

/* Custom color for dark background and light text in specific sections */
body.dark-mode .cta, body.dark-mode .footer {
  background-color: #181818; /* Dark background for sections */
  color: #e0e0e0; /* Light text for dark mode */
}


/* styles.css or global.css */
html, body {
  overflow-x: hidden;
}



